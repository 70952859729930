import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
      }
      html
    }
  }
`
export default function BlogPost(props) {

  return (
    <>
      <Helmet title={`${props.data.markdownRemark.frontmatter.title} | Blog | ${props.data.site.siteMetadata.title}`} />
      <h1 className="title">{props.data.markdownRemark.frontmatter.title}</h1>
      <p>
        <em>
          <time style={{fontSize: "0.9rem", color:"hsl(0, 0%, 21%)"}} datetime={props.date}>
            {props.data.markdownRemark.frontmatter.date}
          </time>
        </em>
      </p>
      <div className="content" dangerouslySetInnerHTML={{ __html: props.data.markdownRemark.html }}></div>
    </>
  )
}